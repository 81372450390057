<template>

<div class="container px-2 ">

    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Users
        </h1>
        <LinkTemplate :to="{path:'/users/create'}" class="my-6 ml-6">
            Add New User
        </LinkTemplate>

    </div>
    <div class="">

        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <TableTemplate v-if="loading == false" @item:deleted="ItemDeleted" :candelete="true" canview="/users/" :columns="columns" :items="items"></TableTemplate>
    <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">

</div>

</template>

<script>
import List from '../../../mixins/List'
import Button from "../../../components/Button";
import Axios from "axios";
export default {
    name: 'ServiceList',
    mixins: [List],
    data: () => ({
        action: '/users',
        searchParams: {
            'model': 'User',
            'fields': ['name','email','id'],
        },
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'name',
                label:'Name',
            },
            {
                id:'email',
                label:'Email',
            },
        ],
    }),
    computed: {
    },
    props: [],
    methods: {
      ItemDeleted: function (item){
        // console.log(item);
        // let id = item.item.id;
        // Axios.get(`/users/remove/${id}`).then(response => console.log(response));
      }
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
      Button
    },
}
</script>
<style scoped>
</style>
