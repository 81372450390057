<template>
    <div class="p-16">
        <h1 class="text-red text-lg mb-6">
            Edit User
        </h1>
      <strong>User Since: </strong>{{showCreatedAt()}}
      <br>
      <strong>Last Updated: </strong>{{showUpdatedAt()}}
        <div class="" v-if="item">
            <FormTemplate @response="formResponse" button="Save" method="post" action="/user" :formdata="formData">
                <TextInput type="text" label="Name" v-model="item.name" :required="false" ></TextInput>
                <TextInput type="text" label="Email" v-model="item.email" :required="false" ></TextInput>
                <Checkbox label="Update Password" v-model="item.update_password" :required="false" ></Checkbox>
                <TextInput v-if="item.update_password" type="password" label="New Password" v-model="item.new_password" :required="false" ></TextInput>
                <SearchSelectInput @item:removed="roleRemoved" @item:selected="roleSelected" :params="roleSearchParams" :multiple="true" label="Roles" v-model="item.roles" :optiondisplay="roleDisplay"></SearchSelectInput>
                <ButtonTemplate :on_click="ResetPassword" btn_style="secondary" class="my-6 mx-2" type="button">
                    Reset Password
                </ButtonTemplate>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'
import Axios from 'axios'
export default {
    name: 'User',
    mixins: [View],
    data: () => ({
        roleSearchParams: {
            'model': 'Role',
            'fields': ['name'],
            'action': '/search/',
        },
        parameter: 'user_id',
        action: '/user/',
    }),
    computed: {
        formData() {
            return {
                'user':this.item,
            }
        },
    },
    props: [],
    methods: {
        ResetPassword() {
            let that = this;
            Axios.post('/user/resetpw',{
                user: that.item,
            }).then(re=>{
                if(re.data && re.data.status == 'OK') {
                    that.$notify({
                        title: 'Password Rest Successful',
                        text: re.data.reset_status,
                        type: 'success',
                    });
                }
            }).catch(err=>{
                console.log(err);
                if(err.response.status == 403) {
                    that.$notify({
                        title: 'You are not allowed to do this...',
                        text: 'Get lost',
                        type: 'error',
                    });
                }
            });
        },
        roleDisplay(item) {
            return item.name;
        },
        roleSelected(evt) {
            let index = this.item.roles.findIndex((role)=>{return role.id == evt.item.id})
            if(index < 0) this.item.roles.push(evt.item);
            if(evt.item.type == 'parent') {
                for(let i in evt.item.children) {
                    let ci = this.item.roles.findIndex((role)=>{return role.id == evt.item.children[i]})
                    if(ci < 0) this.item.roles.push(evt.item.children[i]);
                }
            }
        },
        roleRemoved(evt) {
            this.item.roles.splice(evt.index,1);
        },
      showCreatedAt(){
        let createdDate = new Date(this.item.created_at);

        return createdDate.toLocaleDateString();
      },
      showUpdatedAt(){
        let updatedDate = new Date(this.item.updated_at);

        return updatedDate.toLocaleDateString();
      }
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
