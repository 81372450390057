<template>
    <div class="pt-16 px-2 ">
        <h1 class="text-red text-lg mb-6">
            Create User
        </h1>
        <div class="" >
            <FormTemplate @response="formResponse" button="Create" method="post" action="/user/create" :formdata="formData">
                <TextInput type="text" label="Name" v-model="item.name" :required="false" ></TextInput>
                <TextInput type="text" label="Email" v-model="item.email" :required="false" ></TextInput>
                <TextInput type="text" label="Password" v-model="item.password" :required="false" ></TextInput>
                <SearchSelectInput @item:removed="roleRemoved" @item:selected="roleSelected" :params="roleSearchParams" :multiple="true" label="Roles" v-model="item.roles" :optiondisplay="roleDisplay"></SearchSelectInput>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import Create from '../../../mixins/Create'

export default {
    name: 'CreateUser',
    mixins: [Create],
    data: () => ({
        back: '/users',
        item: {
            name: '',
            email: '',
            password: '',
            roles: [],
        },
        roleSearchParams: {
            'model': 'Role',
            'fields': ['name'],
            'action': '/search/',
        },
        action: '/user/',
    }),
    computed: {
        formData() {
            return {
                'user':this.item,
            }
        },
    },
    props: [],
    methods: {
        roleDisplay(item) {
            return item.name;
        },
        roleSelected(evt) {
            let index = this.item.roles.findIndex((role)=>{return role.id == evt.item.id})
            if(index < 0) this.item.roles.push(evt.item);
            if(evt.item.type == 'parent') {
                for(let i in evt.item.children) {
                    let ci = this.item.roles.findIndex((role)=>{return role.id == evt.item.children[i]})
                    if(ci < 0) this.item.roles.push(evt.item.children[i]);
                }
            }
        },
        roleRemoved(evt) {
            this.item.roles.splice(evt.index,1);
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
